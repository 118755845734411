//@ts-nocheck
import {
  XGInputSearch,
  XGCheckBox,
  XGDatePicker,
  XGRadio,
  XGAutocomplete,
  XGDateRangePicker
} from '@xg-cl/xg-cl';
import TripsList from '../TripsList/TripsList';
import * as PropTypes from 'prop-types';
import { Box } from '@mui/material';
import useFetchData from '../../../hooks/useFetchData';
// import Skeleton from '@mui/material/Skeleton';
import TripTableSkeleton from '../../Skeleton/TripTableSkeleton/TripTableSkeleton';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDate, getMonth, getYear, subDays, subMonths } from 'date-fns';
import NoTripsFound from '../NoTripsFound/NoTripsFound';
import './TripsPanel.css';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';

const TripsPanel = ({ destinations, tripStatuses }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(['trips']);
  const location = useLocation();
  const [gridRows, setGridRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(() => {
    if (searchParams.get('skip')) {
      const skip = parseInt(searchParams.get('skip'));
      if (skip !== 0) {
        return skip / 10 + 1;
      }
    }
    return 1;
  });

  //State for search
  const [inputFilterText, setInputFilterText] = useState(
    searchParams.get('input') ? searchParams.get('input') : ''
  );

  //State for statuses
  const [selectedStatuses, setSelectedStatuses] = useState(
    searchParams.get('statusId') ? searchParams.get('statusId').split(';') : []
  );

  //State for destination
  const [selectedDestination, setSelectedDestination] = useState(() => {
    if (searchParams.get('cityId') && !searchParams.get('countryId')) {
      const filteredDestinations = destinations.filter(
        (destination) =>
          destination.cityId === parseInt(searchParams.get('cityId'))
      );
      return filteredDestinations.length > 0 ? filteredDestinations[0] : null;
    }

    if (searchParams.get('countryId') && !searchParams.get('cityId')) {
      const filteredDestinations = destinations.filter(
        (destination) =>
          destination.countryId === parseInt(searchParams.get('countryId'))
      );
      return filteredDestinations.length > 0 ? filteredDestinations[0] : null;
    }
    return null;
  });

  //State for last modified radio buttons
  const [quickModifiedDate, setQuickModifiedDate] = useState('');

  const {
    data: tripsData,
    isLoading: tripDataLoading,
    error: tripsDataError
  } = useFetchData(`/trips${location.search}`);

  const handlePaginationChange = (event, page) => {
    searchParams.delete('take');
    searchParams.delete('skip');
    searchParams.set('take', 10);
    searchParams.set('skip', (page - 1) * 10);
    setSearchParams(searchParams);
    setCurrentPage(page);
  };

  const handleLastModifiedRadio = (event) => {
    const value = event.target.value;
    setQuickModifiedDate(value);
  };

  const handleModifiedDateChange = (selectedDate) => {
    const [startDate, endDate] = selectedDate;
    const startDateFilter = `${getYear(startDate)}-${
      getMonth(startDate) + 1
    }-${getDate(startDate)}`;
    const endDateFilter = `${getYear(endDate)}-${
      getMonth(endDate) + 1
    }-${getDate(endDate)}`;

    searchParams.delete('lastModified');
    searchParams.set('lastModified', `${startDateFilter};${endDateFilter}`);
    setSearchParams(searchParams);
    setQuickModifiedDate('');
  };

  const handleClearAll = () => {
    searchParams.delete('take');
    searchParams.delete('skip');
    searchParams.delete('statusId');
    searchParams.delete('input');
    searchParams.delete('countryId');
    searchParams.delete('cityId');
    searchParams.delete('startDate');
    searchParams.delete('lastModified');
    setSearchParams(searchParams);
    setInputFilterText('');
    setSelectedStatuses([]);
    setSelectedDestination(null);
    setQuickModifiedDate('');
  };

  //Set up selected destination
  useEffect(() => {
    searchParams.delete('countryId');
    searchParams.delete('cityId');
    if (selectedDestination) {
      if (selectedDestination?.cityId) {
        searchParams.set('cityId', selectedDestination?.cityId);
      } else {
        searchParams.set('countryId', selectedDestination?.countryId);
      }
      setSearchParams(searchParams);
    }
  }, [selectedDestination, searchParams, setSearchParams]);

  //Add the query params when any of statuses are selected
  useEffect(() => {
    if (selectedStatuses.length !== 0) {
      searchParams.set('statusId', selectedStatuses.join(';'));
    }
    setSearchParams(searchParams);
    return () => {
      searchParams.delete('statusId');
    };
  }, [selectedStatuses, searchParams, setSearchParams]);

  //Add query params when any of the lastModified radios are selected
  useEffect(() => {
    if (quickModifiedDate.length !== 0) {
      const todayDate = new Date();
      const sevenDaysAgo = subDays(todayDate, 7);
      const monthAgo = subMonths(todayDate, 1);
      const endDate = `${getYear(todayDate)}-${
        getMonth(todayDate) + 1
      }-${getDate(todayDate)}`;
      let startDate = '';

      if (quickModifiedDate === 'last-seven-days') {
        startDate = `${getYear(sevenDaysAgo)}-${
          getMonth(sevenDaysAgo) + 1
        }-${getDate(sevenDaysAgo)}`;
      } else {
        startDate = `${getYear(monthAgo)}-${getMonth(monthAgo) + 1}-${getDate(
          monthAgo
        )}`;
      }

      const lastModifiedFilter = `${startDate};${endDate}`;
      searchParams.delete('lastModified');
      searchParams.set('lastModified', lastModifiedFilter);
      setSearchParams(searchParams);
    }
  }, [quickModifiedDate, searchParams, setSearchParams]);

  useEffect(() => {
    //Set up the datagrid rows once trips data has loaded
    if (tripsData && tripsData.trips.length > 0) {
      const rows = [];
      tripsData.trips.map(
        ({
          tripid,
          tripname,
          city,
          country,
          startdate,
          modifieddatetime,
          tripstatusid,
          createdByUser
        }) => {
          const cityName = city?.cityname;
          const countryName = country?.countryname;

          const newStartDate = startdate
            ? startdate.split('T')[0].toString()
            : null;

          const trip = {
            id: tripid,
            title: tripname,
            destination: cityName || countryName || '-',
            lastModified: modifieddatetime
              ? formatDate(new Date(modifieddatetime), 'dd LLL yyyy')
              : '-',
            startDate: startdate
              ? formatDate(
                  utcToZonedTime(new Date(newStartDate), 'UTC'),
                  'dd MMM yyyy',
                  { timeZone: 'UTC' }
                )
              : '-',
            status: tripStatuses.find(
              (tripstatus) => tripstatus.tripstatusid === tripstatusid
            ).tripstatuscode,
            createdByUser
          };

          rows.push(trip);
        }
      );
      setGridRows(rows);
    }
  }, [tripsData, tripStatuses]);

  return (
    <Box data-testid='trips-component'>
      <Box
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '8px',
          marginBottom: '8px',
          width: '450px'
        }}
      >
        <XGInputSearch
          className='trips-inputFilter'
          id='trips-filter-input'
          placeholder={t('searchByTitle')}
          name='search'
          type='text'
          onChange={(e) => {
            setInputFilterText(e.target.value);
          }}
          onClick={() => {
            searchParams.delete('input');
            if (inputFilterText.trim().length !== 0) {
              searchParams.set('input', inputFilterText);
            }
            setSearchParams(searchParams);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              searchParams.delete('input');
              if (inputFilterText.trim().length !== 0) {
                searchParams.set('input', inputFilterText);
              }
              setSearchParams(searchParams);
            }
          }}
          value={inputFilterText}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box className='trip-filters'>
          <Box className='trip-filters__header'>
            <span className='trip-filters__header-text trip-filters__filter-by'>
              {t('filterBy')}
            </span>
            <span
              className='trip-filters__header-text trip-filters__clear-all'
              onClick={handleClearAll}
            >
              {t('clearAll')}
            </span>
          </Box>
          <Box className='trip-filters__body'>
            <Box>
              <p className='trips-filters__filter-title'>{t('status')}</p>
              <Box className='trips-filters__checkbox-main-wrapper'>
                {tripStatuses.length !== 0 &&
                  tripStatuses.map((tripStatus) => {
                    return (
                      <Box
                        key={tripStatus.tripstatusid}
                        className='trips-filters__checkbox-wrapper'
                      >
                        <XGCheckBox
                          id={`${tripStatus.tripstatusid}`}
                          value={tripStatus.tripstatusid}
                          size='small'
                          label={tripStatus.tripstatusname}
                          style={{ marginRight: '12px' }}
                          checked={selectedStatuses.includes(
                            String(tripStatus.tripstatusid)
                          )}
                          onChange={(e) => {
                            const statusId = e.target.value;
                            if (selectedStatuses.includes(statusId)) {
                              setSelectedStatuses((prevStatus) =>
                                prevStatus.filter(
                                  (status) => status !== statusId
                                )
                              );
                            } else {
                              setSelectedStatuses((prevValues) => [
                                ...prevValues,
                                e.target.value
                              ]);
                            }
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Box>
            <Box className='trips-filters__dates-wrapper'>
              <Box className='trips-filters__autocomplete-wrapper'>
                <p className='trips-filters__filter-title'>
                  {t('destination')}
                </p>

                <XGAutocomplete
                  placeholder={t('searchDestination')}
                  name='destination'
                  id='trip-destination-input'
                  options={destinations}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value?.title
                  }
                  onChange={(event, newValue) => {
                    setSelectedDestination(newValue);
                  }}
                  value={selectedDestination}
                />
              </Box>
              <Box className='trips-filters__startDatePicker'>
                <p className='trips-filters__filter-title'>{t('startDate')}</p>
                <XGDatePicker
                  format={`${t('common:dateFormat')}`}
                  id='trip-start-date-input'
                  name='startDate'
                  onChange={(selectedDate) => {
                    const day = getDate(selectedDate);
                    const month = getMonth(selectedDate);
                    const year = getYear(selectedDate);

                    searchParams.delete('startDate');
                    searchParams.set(
                      'startDate',
                      `${year}-${month + 1}-${day}`
                    );
                    setSearchParams(searchParams);
                  }}
                  value={
                    searchParams.get('startDate')
                      ? new Date(searchParams.get('startDate'))
                      : null
                  }
                />
              </Box>
              <Box>
                <p className='trips-filters__filter-title'>
                  {t('lastModified')}
                </p>
                <Box className='trips-filters__range-picker-wrapper'>
                  <XGDateRangePicker
                    id='trips-filters-lastmodified-date-range'
                    format={`${t('common:dateFormat')}`}
                    onChange={handleModifiedDateChange}
                    xs={{ paddingTop: '0px' }}
                    value={
                      searchParams.get('lastModified')
                        ? [
                            new Date(
                              searchParams.get('lastModified').split(';')[0]
                            ),
                            new Date(
                              searchParams.get('lastModified').split(';')[1]
                            )
                          ]
                        : [null, null]
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box className='trips-filters__lastmodified-radio-wrapper'>
                <XGRadio
                  id='trips-filters-last-seven-days'
                  label={t('last7Days')}
                  size='small'
                  name='trips-filters-lastmodified-input'
                  value='last-seven-days'
                  checked={quickModifiedDate === 'last-seven-days'}
                  onChange={handleLastModifiedRadio}
                  style={{ marginBottom: '16px' }}
                />
              </Box>
              <Box className='trips-filters__lastmodified-radio-wrapper'>
                <XGRadio
                  id='trips-filters-last-month'
                  label={t('lastMonth')}
                  size='small'
                  name='trips-filters-lastmodified-input'
                  value='last-month'
                  checked={quickModifiedDate === 'last-month'}
                  onChange={handleLastModifiedRadio}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {tripDataLoading ? (
          <Box
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
          >
            <TripTableSkeleton id='trips-table-skeleton' />
          </Box>
        ) : null}
        {!tripDataLoading && !tripsDataError && tripsData ? (
          tripsData.trips.length !== 0 ? (
            <TripsList
              trips={gridRows}
              totalRecordsCount={tripsData.count}
              onPaginationChange={handlePaginationChange}
              currentPage={currentPage}
            />
          ) : (
            <Box
              style={{
                width: '83%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <NoTripsFound />
            </Box>
          )
        ) : null}
      </Box>
    </Box>
  );
};

TripsPanel.propTypes = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      countryId: PropTypes.number,
      cityId: PropTypes.number,
      title: PropTypes.string
    })
  ),
  tripStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      tripstatusid: PropTypes.number,
      description: PropTypes.string,
      tripstatuscode: PropTypes.string,
      tripstatusname: PropTypes.string
    })
  )
};

export default TripsPanel;
